<script>
/**
 * Devices Data component
 */
import { authComputed, alertsMethods, alertsComputed } from "@/state/helpers";
import moment from 'moment';
export default {
  props: ["deviceRef", "selectedType"],
  components: {},
  data() {
    return {
      alertsList: [],
      currentPage: 1,
      perPage: 50,
      busy: true,
    };
  },
  computed: {
    ...authComputed,
    ...alertsComputed,
    fieldsAlertsComputed() {
      return [
        {
          key: "date",
          sortable: false,
          label: this.$t("dataTable.dateAlert.text"),
        },
        { key: "serial", sortable: false, label: "Serial" },
        { key: "ref", sortable: false, label: "Reference" },
        {
          key: "state",
          sortable: false,
          label: this.$t("dataTable.status.text"),
        },
        {
          key: "level",
          sortable: false,
          label: this.$t("dataTable.levelAlert.text"),
        },
        {
          key: "value",
          sortable: false,
          label: this.$t("dataTable.value.text"),
        },
      ];
    },
  },
  mounted() {},
  methods: {
    ...alertsMethods,
    retrieveAlerts({ ref }) {
      let params = {
        page: this.currentPage,
        limit: this.perPage,
        type: this.selectedType,
        ref: ref,
        all: true,
        sortBy: "createdAt:desc",
      };
      this.getSubAlertsBy(params);
    },
    rowClicked(record) {
      console.log(record);
    },
    openDevice(device) {
      let routerLink = this.$router.resolve({
        name: "device",
        params: { serial: device.serial },
      });
      window.open(routerLink.href, "_blank");
    },
  },
  watch: {
    deviceRef(newVal) {
      this.busy = true;
      this.currentPage = 1;
      if(newVal) this.retrieveAlerts({  ref: newVal });
    },
    getSubAlerts(newVal) {
      this.alertsList = [];
      this.busy = false;
      this.alertsList = newVal.results.map(obj=>{
        let vaccinTypes = obj.vaccinTypes.map(vaccin=> {return vaccin.name});
        return {
            ...obj,
            state: obj.state ? obj.state.name : null,
            province: obj.province ? obj.province.name : null,
            region: obj.region ? obj.region.name : null,
            date: moment(obj.alertedAt).format("DD-MM-YYYY HH:mm:ss"),
            stockType: obj.stockType ? obj.stockType : "-",
            vaccinTypes: vaccinTypes ? vaccinTypes : "-"
        }
      })
      
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="alerts-modal"
      ref="alerts-modal"
      size="xl"
      scrollable
      hide-footer
      :title="this.$t('titles.alerts.text')"
    >
      <div class="row">
        <div id="tableAlerts" class="col-12">
          <div
            class="table-responsive mb-3 bg-white rounded-lg"
            style="min-height: 300px"
          >
            <b-table
              class="mb-0"
              hover
              show-empty
              centred
              :busy="busy"
              empty-text="No Alerts"
              :items="alertsList"
              :fields="fieldsAlertsComputed"
              stacked="sm"
            >
            <template #table-busy>
              <div class="text-center my-2">
                <strong>{{ $t("dataTable.loading.text") }}</strong>
              </div>
            </template>
              <template v-slot:cell(state)="row">
                <div
                    class="badge font-size-12 text-center"
                    :class="{
                    'badge-soft-warning': `${row.item.state}` === 'NEW',
                    'badge-soft-info': `${row.item.state}` === 'OPENED',
                    'badge-soft-secondary': `${row.item.state}` === 'SUSPENDED',
                    'badge-soft-success': `${row.item.state}` === 'CLOSED',
                    }"
                >
                    {{ row.item.state }}
                </div>
                </template>
                <template v-slot:cell(type)="row">
                <div
                    class="badge font-size-12 text-center"
                    :class="{
                    'badge-soft-primary': `${row.item.type}` === 'DOWN',
                    'badge-soft-danger': `${row.item.type}` === 'UP',
                    }"
                >
                    {{ row.item.type }}
                </div>
                </template>
                <template v-slot:cell(level)="row">
                <div
                    class="font-size-12 text-center fw-bold"
                    :style="{
                    'color': `${row.item.level}` === 'LOW' ? '#d9d630' : `${row.item.level}` === 'MEDIUM' ? '#d58115': '#db1717',
                    'border': `${row.item.level}` === 'LOW' ? '2px solid yellow' : `${row.item.level}` === 'MEDIUM' ? '2px solid orange': '2px solid red',
                    }"
                >
                    {{ row.item.level }}
                </div>
                </template>
                <template v-slot:cell(value)="row">
                <div
                    class="font-size-14 text-center font-weight-bold"
                    :class="{
                    'text-danger': `${row.item.value}` > 8,
                    'text-primary': `${row.item.value}` < 2,
                    }"
                >
                    {{ row.item.value }}
                </div>
                </template>
                <template v-slot:cell(date)="row">
                <div class="text-center">
                    {{ row.item.date }}
                </div>
                </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
/* #tableLogs{
    overflow-y: scroll;
    max-height: 400px;
} */
</style>