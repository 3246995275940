<script>
/**
 * Devices Data component
 */
import moment from "moment";
import { authComputed, logsMethods, logsComputed } from "@/state/helpers";
export default {
  props: ["deviceRef"],
  components: {},
  data() {
    return {
      logsList: [],
      currentPage: 1,
      perPage: 6,
      moreLogs: true,
      busy: true,
      moreLoader: false,

      dates: [],
      fromTime: "00:00",
      toTime: "00:00",
      fromContext: null,
      toContext: null,
      minDate: "2000-01-01",
      menu: false,
    };
  },
  computed: {
    ...authComputed,
    ...logsComputed,
    dateRangeText() {
      if (this.dates.length > 0) {
        return this.dates.join(" ~ ");
      } else {
        return "Export XLS !";
      }
    },
    fieldsComputed() {
      return [
        {
          key: "date",
          sortable: false,
          label: this.$t("dataTable.dateTime.text"),
        },
        {
          key: "alertedAt",
          sortable: false,
          label: this.$t("dataTable.dateAlert.text"),
        },
        { key: "serial", sortable: false, label: "Serial" },
        { key: "ref", sortable: false, label: "Reference" },
        { key: "user", sortable: false, label: this.$t("dataTable.user.text") },
        {
          key: "state",
          sortable: false,
          label: this.$t("dataTable.status.text"),
        },
        {
          key: "action",
          sortable: false,
          label: this.$t("dataTable.action.text"),
        },
        {
          key: "operation",
          sortable: false,
          label: "Operation",
        },
        {
          key: "cause",
          sortable: false,
          label: this.$t("dataTable.cause.text"),
        },
        {
          key: "value",
          sortable: false,
          label: this.$t("dataTable.value.text"),
        },
        {
          key: "savedDoses",
          sortable: false,
          label: this.$t("dataTable.savedDoses.text"),
        },
        {
          key: "timeAquit",
          sortable: false,
          label: "TR",
        },
        { key: "note", sortable: false, label: this.$t("dataTable.note.text") },
      ];
    },
  },
  mounted() {},
  methods: {
    ...logsMethods,
    retrieveLogs({ ref }) {
      let params = {
        page: this.currentPage,
        limit: this.perPage,
        ref: ref,
        sortBy: "createdAt:desc",
        more: true,
      };
      this.getLogsBy(params);
    },
    showMore() {
      this.moreLoader = true;
      this.currentPage = this.currentPage + 1;
      this.retrieveLogs({ ref: this.deviceRef });
    },
    rowClicked(record) {
      console.log(record);
    },
    openDevice(device) {
      let routerLink = this.$router.resolve({
        name: "device",
        params: { serial: device.serial },
      });
      window.open(routerLink.href, "_blank");
    },
    exportData(device) {

      
      if (this.dates.length == 2) {
        let from = `${this.dates[0]} ${this.fromTime}`;
        let to = `${this.dates[1]} ${this.toTime}`;
        if (this.dates[0] == this.dates[1]) {
          let fromHoure = this.fromContext.hours;
          let fromMinute = this.fromContext.minutes;
          let toHoure = this.toContext.hours;
          let toMinute = this.toContext.minutes;
          if (toHoure >= fromHoure) {
            if (
              toHoure > fromHoure ||
              (toHoure == fromHoure && toMinute >= fromMinute)
            ) {
              this.$refs.menu.save(this.dates);
              let params = {
                serial: device.serial,
                from: from,
                to: to,
              };
              this.exportXLS(params);
            } else {
              this.makeToast(
                "Date",
                this.$t("msgError.periodDate.text"),
                "danger"
              );
            }
          } else {
            this.makeToast(
              "Date",
              this.$t("msgError.periodDate.text"),
              "danger"
            );
          }
          /*  */
        } else {
          this.$refs.menu.save(this.dates);
          let params = {
            serial: device.serial,
            from: from,
            to: to,
          };
          this.exportXLS(params);
        }
      }
      /* this.exportXLS(params);
      console.log(device); */
    },
    onFromContext(ctx) {
      this.fromContext = ctx;
    },
    onToContext(ctx) {
      this.toContext = ctx;
    },
  },
  watch: {
    deviceRef(newVal) {
      this.busy = true;
      this.moreLogs = true;
      this.currentPage = 1;
      this.retrieveLogs({  ref: newVal });
    },
    getLogs(newVal) {
      this.logsList = [];
      this.busy = false;
      this.moreLoader = false;
      if (newVal.results.length == newVal.totalResults) {
        this.moreLogs = false;
      }
      newVal.results.map((obj) => {
        let savedDosesFiltered = obj.savedDoses.map((obj) => {
          return `${obj.vaccin}: <b>${obj.number}</b>`;
        });
        this.logsList.push({
          ...obj,
          timeAquit: obj.timeAquit ? Math.floor(obj.timeAquit / 60) + 'h' + obj.timeAquit % 60 + 'min' : '-',
          user: obj.user ? obj.user.fullName : "-",
          state: obj.state ? obj.state.name : "-",
          operation: obj.operation ? obj.operation.name : "-",
          action: obj.action ? obj.action.name : "-",
          cause: obj.cause ? obj.cause.name : "-",
          savedDoses: savedDosesFiltered.join("<br />"),
          date: moment(obj.createdAt).format("DD-MM-YYYY HH:mm:ss"),
          alertedAt: moment(obj.alertedAt).format("DD-MM-YYYY HH:mm:ss"),
        });
      });
    },
    dates(newVal) {
      if (newVal.length == 1) {
        this.minDate = newVal[0];
      } else {
        this.minDate = "2000-01-01";
      }
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="logs-modal"
      ref="logs-modal"
      size="xl"
      scrollable
      hide-footer
      :title="this.$t('titles.logs.text')"
    >
      <div class="row">
        <div id="tableLogs" class="col-12">
          <div
            class="table-responsive mb-3 bg-white rounded-lg"
            style="min-height: 300px"
          >
            <b-table
              class="mb-0"
              hover
              show-empty
              centred
              :busy="busy"
              :loading="busy"
              :loading-text="this.$t('dataTable.loading.text')"
              empty-text="No Logs"
              :items="logsList"
              :fields="fieldsComputed"
              stacked="sm"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <strong>{{ $t("dataTable.loading.text") }}</strong>
                </div>
              </template>
              <template v-slot:cell(state)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-warning': `${row.value}` === 'NEW',
                    'badge-soft-info': `${row.value}` === 'OPENED',
                    'badge-soft-secondary': `${row.value}` === 'SUSPENDED',
                    'badge-soft-danger': `${row.value}` === 'CLOSED',
                    'badge-soft-dark': `${row.value}` === 'IGNORED',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(savedDoses)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(value)="row">
                <div
                  class="font-size-14 text-center font-weight-bold"
                  :class="{
                    'text-danger': `${row.value}` > 8,
                    'text-primary': `${row.value}` < 2,
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div v-if="moreLogs" class="col-6">
          <div v-if="!moreLoader && moreLogs" class="row">
            <div class="col-12">
              <b-button
                id="moreLogs"
                type="button"
                variant="info"
                @click="showMore()"
                >{{ $t("buttons.moreLogs.text") }}
              </b-button>
            </div>
          </div>
          <div v-if="moreLoader" class="row">
            <div class="col-12">
              <div
                class="d-flex justify-content-center align-items-center"
                style="position: relative; z-index: 200"
              >
                <b-spinner label="Loading..." variant="primary"></b-spinner>
              </div>
            </div>
          </div>
        </div>
        <div v-if="moreLogs" class="col-6">
          <div class="row">
            <!-- <div class="col-12 text-right">
              <b-button variant="danger" @click="openDevice(device)">{{
                $t("buttons.viewDevice.text")
              }}</b-button>
            </div> -->
            <!-- <div class="col-6 text-right" data-app>
              <v-menu
                  ref="menu"
                  class="mt-0"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    no-title
                    range
                    scrollable
                    color="#e52342"
                    :min="minDate"
                  >
                    <div class="row">
                      <div class="col-6 d-flex">
                        <b-time
                          hide-header
                          locale="fr"
                          v-model="fromTime"
                          style="font-size: 4px"
                          @context="onFromContext"
                        ></b-time>
                      </div>
                      <div class="col-6 d-flex">
                        <b-time
                          hide-header
                          locale="fr"
                          v-model="toTime"
                          style="font-size: 4px"
                          @context="onToContext"
                        ></b-time>
                      </div>
                      <div class="col-4 text-center">
                        <b-button
                          class="mb-1"
                          variant="success"
                          @click="exportData(device)"
                        >
                          {{ $t("buttons.exportXLS.text") }}
                        </b-button>
                      </div>
                    </div>
                  </v-date-picker>
                </v-menu>
            </div> -->
          </div>
        </div>
        <div v-if="!moreLogs" class="col-12">
          <div class="row">
            <div class="col-12 text-right">
              <!-- <b-button variant="danger" @click="openDevice(device)">{{
                $t("buttons.viewDevice.text")
              }}</b-button> -->
            </div>
            <!-- <div class="col-6 text-right" data-app>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    no-title
                    range
                    scrollable
                    color="#e52342"
                    :min="minDate"
                  >
                    <div class="row">
                      <div class="col-6 d-flex">
                        <b-time
                          hide-header
                          locale="fr"
                          v-model="fromTime"
                          style="font-size: 4px"
                          @context="onFromContext"
                        ></b-time>
                      </div>
                      <div class="col-6 d-flex">
                        <b-time
                          hide-header
                          locale="fr"
                          v-model="toTime"
                          style="font-size: 4px"
                          @context="onToContext"
                        ></b-time>
                      </div>
                      <div class="col-4 text-center">
                        <b-button
                          class="mb-1"
                          variant="success"
                          @click="exportData(device)"
                        >
                          {{ $t("buttons.exportXLS.text") }}
                        </b-button>
                      </div>
                    </div>
                  </v-date-picker>
                </v-menu>
            </div> -->
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
/* #tableLogs{
    overflow-y: scroll;
    max-height: 400px;
} */
</style>