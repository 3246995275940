var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"alerts-modal",attrs:{"id":"alerts-modal","size":"xl","scrollable":"","hide-footer":"","title":this.$t('titles.alerts.text')}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",attrs:{"id":"tableAlerts"}},[_c('div',{staticClass:"table-responsive mb-3 bg-white rounded-lg",staticStyle:{"min-height":"300px"}},[_c('b-table',{staticClass:"mb-0",attrs:{"hover":"","show-empty":"","centred":"","busy":_vm.busy,"empty-text":"No Alerts","items":_vm.alertsList,"fields":_vm.fieldsAlertsComputed,"stacked":"sm"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t("dataTable.loading.text")))])])]},proxy:true},{key:"cell(state)",fn:function(row){return [_c('div',{staticClass:"badge font-size-12 text-center",class:{
                  'badge-soft-warning': `${row.item.state}` === 'NEW',
                  'badge-soft-info': `${row.item.state}` === 'OPENED',
                  'badge-soft-secondary': `${row.item.state}` === 'SUSPENDED',
                  'badge-soft-success': `${row.item.state}` === 'CLOSED',
                  }},[_vm._v(" "+_vm._s(row.item.state)+" ")])]}},{key:"cell(type)",fn:function(row){return [_c('div',{staticClass:"badge font-size-12 text-center",class:{
                  'badge-soft-primary': `${row.item.type}` === 'DOWN',
                  'badge-soft-danger': `${row.item.type}` === 'UP',
                  }},[_vm._v(" "+_vm._s(row.item.type)+" ")])]}},{key:"cell(level)",fn:function(row){return [_c('div',{staticClass:"font-size-12 text-center fw-bold",style:({
                  'color': `${row.item.level}` === 'LOW' ? '#d9d630' : `${row.item.level}` === 'MEDIUM' ? '#d58115': '#db1717',
                  'border': `${row.item.level}` === 'LOW' ? '2px solid yellow' : `${row.item.level}` === 'MEDIUM' ? '2px solid orange': '2px solid red',
                  })},[_vm._v(" "+_vm._s(row.item.level)+" ")])]}},{key:"cell(value)",fn:function(row){return [_c('div',{staticClass:"font-size-14 text-center font-weight-bold",class:{
                  'text-danger': `${row.item.value}` > 8,
                  'text-primary': `${row.item.value}` < 2,
                  }},[_vm._v(" "+_vm._s(row.item.value)+" ")])]}},{key:"cell(date)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.item.date)+" ")])]}}])})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }